.bottom-right {
    right: 10px;
    bottom: 10px;
    position: fixed;
    font-size: 10px;
    background: rgba(0, 0, 0, 0);
}

#upload-image {
    display: none;
}

.image-card {
    display: flex;
    width: 96%;
    height: auto;
    border-radius: 4px;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
    font-size: 14px;
    margin: 0px auto;
    padding: 10px;
    flex-direction: column;
}

.card-inner-container {
    height: 100%;
    width: 100%;
    margin: auto auto;
}

.card-desc {
    margin-top: 10px;
}

.ion-page {
    
}