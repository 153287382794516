.MuiDrawer-paper {
    width: 70%;
}

.list {
    position: relative;
    width: 90%;
    background-color: white;
    border-radius: 5%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px !important;
    box-shadow: 0 1px 3px hsla(0,0%,0,.2)
}

.border-bottom:after {
    position: absolute;
    content: '';
    width: 90% !important;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0px;
    border-bottom: solid 1px rgba(0,0,0,0.1) !important;
}

.about-me {
    position: relative;
    width: 95%;
    transform: translateX(2.5%);
    margin-top: 5px;
    background-position: center;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.flex {
    display: flex;
    flex-direction: column;
}

.title {
    font-weight: 700;
}

.about-me {
    padding: 10px 0px;
}