.right {
    float: right;
    margin-top: 6px;
    margin-right: 6px;
    font-size: 32px;
}

span {
    vertical-align: middle;
}

.leftArrow {
    display: none !important;
}

.rightArrow {
    display: none !important;
}