.Home {
    text-align: center;
    
    // div.tooltip {
    //     color: #222; 
    //     background: #fff; 
    //     border-radius: 3px; 
    //     box-shadow: 0px 0px 2px 0px #a6a6a6; 
    //     padding: .2em; 
    //     text-shadow: #f5f5f5 0 1px 0;
    //     opacity: 0.9; 
    //     position: absolute;
    // }
}
#map { 
    position:absolute; 
    top:0; 
    bottom:0; 
    width:100%; 
    background-color: lighten(#252827,5%);
}
.svg {
    width: 100%;
    // height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    g {
        width: 100%;
        height: 100%;
    }
}
.hidden {
    display: none;
}
.active {
    fill: orange;
}

.label {
    text-anchor: middle;
    cursor: pointer;
}

input {
    color: black !important;
}

.map-card {
    height: 400px;
}