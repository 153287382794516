.Landing {

    #logo {
        animation: fill 1s ease forwards 4.0s;
    }

    #logo path:nth-child(1){
        stroke-dasharray: 490.2921142578125px;
        stroke-dashoffset: 490.2921142578125px;
        animation: line-anim 3s ease forwards;
    }
    #logo path:nth-child(2){
        stroke-dasharray: 297.21600341796875px;
        stroke-dashoffset: 297.21600341796875px;
        animation: line-anim 3s ease forwards 0.3s;
    }
    #logo path:nth-child(3){
        stroke-dasharray: 449.73370361328125px;
        stroke-dashoffset: 449.73370361328125px;
        animation: line-anim 3s ease forwards 0.6s;
    }
    #logo path:nth-child(4){
        stroke-dasharray: 534.528076171875px;
        stroke-dashoffset: 534.528076171875px;
        animation: line-anim 3s ease forwards 0.9s;
    }
    #logo path:nth-child(5){
        stroke-dasharray: 490.2921142578125px;
        stroke-dashoffset: 490.2921142578125px;
        animation: line-anim 3s ease forwards 1.2s;
    }
    #logo path:nth-child(6){
        stroke-dasharray: 297.216064453125px;
        stroke-dashoffset: 297.216064453125px;
        animation: line-anim 3s ease forwards 1.5s;
    }
    #logo path:nth-child(7){
        stroke-dasharray: 490.2921142578125px;
        stroke-dashoffset: 490.2921142578125px;
        animation: line-anim 3s ease forwards 1.8s;
    }
    #logo path:nth-child(8){
        stroke-dasharray: 483.89300537109375px;
        stroke-dashoffset: 483.89300537109375px;
        animation: line-anim 3s ease forwards 2.1s;
    }

    @keyframes line-anim{
        to{
            stroke-dashoffset: 0;
        }
    }

    @keyframes fill{
        from{
            fill: transparent;
        }
        to{
            fill: white;
        }
    }

    .main-content {
        // background-image: url('../../assets/background/Konigsee_woods_warm_fall.jpg');
        // background-repeat: no-repeat;
        // background-size: cover;
        height: 100vh;
        width: 100vw;
        display: flex;
        vertical-align: middle;
        justify-content: center;
        font-family: 'Lato', sans-serif;
    }
    
    .title {
        align-self: center;
        position: absolute;
        color: white;
        font-size: calc(10px + 5vmin);
        text-align: center;
    }
    
    #background-video {
        object-fit: fill;
        min-width: 100%; 
        min-height: 100%;
        z-index: -1000;
    }
    
    button {
        cursor: pointer;
        outline: none;
            &.outline {
                position: relative;
                z-index: 3;
                background-color: #920783;
                color: white;
                font-size: 14px;
                border-color: white;
                border-style: solid;
                border-width: 2px;
                border-radius: 22px;
                padding: 10px 40px;
                text-transform: uppercase;
                transition: all 0.2s linear;
            a {
                text-decoration: none;
            }
            a:visited {color:white;} 
        }
        &.outline:active {
            border-radius: 22px;
        }
        &.white-blue {
            font-weight: 700;
            color: #920783;
            border-color: white;
            background: white;
        }
    }
}